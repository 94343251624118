import React from "react";
import style from "../../assets/styles/Utils/ButtonBarRS.module.css";
import { Link } from "react-router-dom";

export default function ButtonBarRS({ parentStyle, text, fontSize, path }) {

    function scroll() {
        const element = document.getElementById("about");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        text ?
            <Link to={path} className={style.container_text_bar} onClick={path == "/#about" ? scroll : null
            }>
                <div>
                    <p className={style.text} style={fontSize && { fontSize: fontSize }}>{text}</p>
                    <div className={`${style.bar} ${parentStyle}`}>
                        <span className={`${style.bars} ${style.green}`}></span>
                        <span className={`${style.bars} ${style.red}`}></span>
                        <span className={`${style.bars} ${style.yellow}`}></span>
                    </div >
                </div>
            </Link> :
            <div className={`${style.bar} ${parentStyle}`}>
                <span className={`${style.bars} ${style.green}`}></span>
                <span className={`${style.bars} ${style.red}`}></span>
                <span className={`${style.bars} ${style.yellow}`}></span>
            </div >
    )
}