import React, { useContext, useEffect, useState } from "react";
import style from "../../assets/styles/Utils/Option.module.css";
import { FiltersContext } from "../context/FilterContext";

export default function Option({ option, index, name }) {

    const filters = useContext(FiltersContext);

    const [isChecked, setIsChecked] = useState(false);

    function handleClick(e) {
        const value = e.target.dataset.value;
        setIsChecked(!isChecked);
        if (!isChecked) {
            filters[name].set([...filters[name].values, value]);
        } else {
            filters[name].set([...filters[name].values.filter(item => item !== value)]);
        }
    }

    useEffect(() => {
        if (filters[name].values.includes(option)) {
            setIsChecked(true);
        }
    }, [])

    return (
        <div onClick={handleClick} data-value={option} key={index} className={style.container_option}>
            <label className={`${style.text_option} ${style.no_click}`}>{option}</label>
            <div className={`${style.no_click} ${isChecked ? `${style.select_option} ${style.selectOption_selected}` : style.select_option}`} />
        </div>
    );
}