import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import AdmContext from "./AdmContext";

export default function PrivateRoute() {
    const { isAuthenticated } = useContext(AuthContext);
    
    return isAuthenticated ? <AdmContext /> : <Navigate to="/login" />;
}
