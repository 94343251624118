import React from "react";

import { FiltersProvider } from "../context/FilterContext";
import Adm from "./Adm";

export default function AdmContext() {
    return (
        <FiltersProvider>
            <Adm />
        </FiltersProvider>
    );
}

