import React, { useContext } from "react";
import { FiltersContext } from "../context/FilterContext";
import style from "../../assets/styles/Companies/AppliedFilters.module.css";

export default function AppliedFilters() {

    const filters = useContext(FiltersContext);

    return (
        <div className={style.applied_filters}>
            {filters.cidades.values.map((cidade, index) => {
                return (
                    <div key={index} className={style.applied_filter}>
                        <p>{cidade}</p>
                    </div>
                );
            })}

            {filters.segmentos.values.map((segmento, index) => {
                return (
                    <div key={index} className={style.applied_filter}>
                        <p>{segmento}</p>
                    </div>
                );
            })}


        </div>
    );
}