import React from "react";
import style from "../../assets/styles/Utils/Footer.module.css";
import Logo from "./Logo";

import whatsApp from "../../assets/images/whatsapp_white.png";
import email from "../../assets/images/email.png";
import instagram from "../../assets/images/instagram_white.png";

export default function Footer() {

    return (
        <footer>
            <div className={style.internal_footer}>
                <Logo style={style}></Logo>

                <div className={style.container_icon}>
                    <div className={style.each_container_icon}>
                        <img className={style.social_icons} src={whatsApp} alt="whatsapp" />
                        <a href="tel:(54) 9 9922-2001">(54) 9 9922-2001</a>
                    </div>
                    <div className={style.each_container_icon}>
                        <img className={style.social_icons} src={email} alt="email" />
                        <a href="mailto:recupereumnegociogaucho@gmail.com" target="_blank">recupereumnegociogaucho@gmail.com</a>
                    </div>
                    <div className={style.each_container_icon}>
                        <img className={style.social_icons} src={instagram} alt="instagram" />
                        <a href="https://www.instagram.com/sosnegociosgauchos" target="_blank">@sosnegociosgauchos</a>
                    </div>
                </div>

                <p className={style.final_text}>Somos uma iniciativa voluntária com mais de 30 colaboradores dedicada a revitalizar o comércio e a economia do Rio Grande do Sul, conectando negócios locais afetados pela tragédia a clientes de todo o Brasil. Entendemos que cada negócio tem uma história única, por isso, nos empenhamos em contá-las. Entre em contato para saber mais.</p>

            </div>
            <p className={style.developed}>Copyright © Todos os direitos reservados à organização.  Desenvolvido por Héric dos Santos Lopes. </p>
        </footer>
    );

}