import React from "react";
import Navbar from "./Utils/Navbar";
import Footer from "./Utils/Footer";
import { Outlet, useLocation } from "react-router-dom";

export default function Public() {

    const location = useLocation();

    const hideNavbarOnPaths = ["/login", "/"];
    const hideFooterOnPaths = ["/login"];

    // Verifica se o caminho atual está na lista de páginas para ocultar a Navbar
    const showNavbar = !hideNavbarOnPaths.includes(location.pathname);

    const showFooter = !hideFooterOnPaths.includes(location.pathname);

    return (
        <div >
            {showNavbar && <Navbar />}
            <Outlet />
            {showFooter && <Footer />}
        </div>
    );
}