import React, { useState } from 'react';

const ImageWithFallback = ({ src, fallbackSrc, alt, style }) => {
    const [currentSrc, setCurrentSrc] = useState(src);
    const [errorCount, setErrorCount] = useState(0);

    const handleError = () => {
        if (errorCount < 2) {
            setCurrentSrc(`${src}?retry=${errorCount}`);
            setErrorCount(errorCount + 1);
        } else if (fallbackSrc) {
            setCurrentSrc("");
        }
    };

    return (
        <img className={style.image} src={currentSrc} alt={alt} onError={handleError} />
    );
};

export default ImageWithFallback;