import React from "react";

import { FiltersProvider } from "../context/FilterContext";
import Companies from "./Companies";

export default function Catalog() {
    return (
        <FiltersProvider>
            <Companies />
        </FiltersProvider>
    );
}