import React from "react";
import style from "../../assets/styles/Home/About.module.css";
import ButtonBarRS from "../Utils/ButtonBarRS";
import faixa_rs from "../../assets/images/faixa_rs_about.png";

export default function About() {
    return (
        <section id="about" className={style.sobre_nos}>
            <div className={style.internal_sobre_nos}>
                <h1 className={style.title}>Sobre Nós</h1>

                <div className={style.text_container}>
                    <div className={style.div_texts}>
                        <h3>Nossa missão</h3>
                        <p>A SOS Negócios Gaúchos nasceu com o propósito de apoiar a recuperação econômica do Rio Grande do Sul, que foi duramente afetado pelas enchentes em maio de 2024. Nossa missão é criar um canal direto entre os negócios locais e os consumidores, promovendo a visibilidade e o crescimento dessas empresas de forma voluntária.</p>
                    </div>
                    <ButtonBarRS parentStyle={style.separateBar} />
                    <div className={style.div_texts}>
                        <h3>O que fazemos</h3>
                        <p>Através de nosso site, oferecemos uma vitrine digital para que os negócios possam se cadastrar e serem facilmente encontrados por clientes interessados em apoiar a economia local. Dividimos os negócios por segmento e localização, facilitando a busca e a conexão. Além disso, fornecemos informações detalhadas sobre cada negócio, para que os consumidores possam fazer escolhas informadas e conscientes.</p>
                    </div>
                    <ButtonBarRS parentStyle={style.separateBar} />
                    <div className={style.div_texts}>
                        <h3>Nosso compromisso</h3>
                        <p>Estamos comprometidos com a recuperação e o fortalecimento do Rio Grande do Sul. Acreditamos que, ao promover a união entre os consumidores e os negócios locais, podemos ajudar a reconstruir a economia e trazer esperança e resiliência para nossas comunidades. Junte-se a nós nessa missão e ajude a fazer a diferença.</p>
                    </div>
                </div>
            </div>

            <img className={`${style.band} ${style.fst_band}`} src={faixa_rs} alt="" />
            <img className={`${style.band} ${style.snd_band}`} src={faixa_rs} alt="" />
        </section>
    );
}