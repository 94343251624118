import axios from "axios";

let baseURL;

if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    baseURL = process.env.REACT_APP_API_URL_DEV;
} else if (process.env.REACT_APP_ENVIRONMENT === 'prd') {
    baseURL = process.env.REACT_APP_API_URL_PRD;
} else if (process.env.REACT_APP_ENVIRONMENT === 'hml') {
    baseURL = process.env.REACT_APP_API_URL_HML;
}

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default axiosInstance;