import React, { useState, createContext } from "react";

export const FiltersContext = createContext();

export function FiltersProvider({ children }) {

    const [filtrosCidades, setFiltrosCidades] = useState([]);
    const [filtrosSegmentos, setFiltrosSegmentos] = useState([]);
    const [filtrosPublicado, setFiltrosPublicado] = useState([]);

    const filtros = {
        cidades: {
            values: filtrosCidades,
            set: setFiltrosCidades
        },
        segmentos: {
            values: filtrosSegmentos,
            set: setFiltrosSegmentos
        },
        publicado: {
            values: filtrosPublicado,
            set: setFiltrosPublicado
        }
    }

    return (
        <FiltersContext.Provider value={filtros}>
            {children}
        </FiltersContext.Provider>
    );
}
