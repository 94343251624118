import React, { useEffect } from "react";
import style from "../../assets/styles/Utils/NotFound.module.css";
import { useNavigate } from "react-router-dom";

export default function NotFound() {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate("/");
        }, 2000)
    }, [])

    return (
        <div className={style.container}>
            <h1 className={style.text}>404 - Página não encontrada</h1>
        </div>
    );
}