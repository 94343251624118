import React, { useEffect, useState } from "react";
import style from "../../assets/styles/Companies/Cadaster.module.css";
import ButtonBarRS from "../Utils/ButtonBarRS";
import axios from "axios";
import axiosInstance from "../../axios/config";
import ImageWithFallback from "../Utils/ImageWithFallback";
import Loading from "../Utils/Loading";

export default function Cadaster() {

    const [necessidades, setNecessidades] = useState({
        doacoes_dinheiro: false,
        mao_de_obra: false,
        doacoes_materiais: false,
        alcancar_clientes: false
    });
    const [loading, setLoading] = useState(false);
    const [cidades, setCidades] = useState([]);
    const [formData, setFormData] = useState({
        empresa: "",
        cnpj: "",
        responsavel: "",
        cpf: "",
        cidade: "",
        segmento: "",
        proposta_negocio: "",
        imagens_produto: [],
        danos: "",
        necessidades: "",
        pix: "",
        itens_doacao: "",
        imagens_antes: [],
        email: "",
        telefone: "",
        endereco: "",
        instagram: "",
        link_site: ""
    });

    const [selectedFilesProdutos, setSelectedFilesProdutos] = useState([]);
    const [selectedFilesAntes, setSelectedFilesAntes] = useState([]);
    const [previewImagesProdutos, setPreviewImagesProdutos] = useState([]);
    const [previewImagesAntes, setPreviewImagesAntes] = useState([]);
    const [exclusao, setExclusao] = useState(false);
    const [lgpd, setLgpd] = useState(false);

    useEffect(() => {
        if (selectedFilesProdutos.length) {
            const newPreviewImages = selectedFilesProdutos.map(file => ({
                file,
                url: URL.createObjectURL(file)
            }));
            if (!exclusao) {
                setPreviewImagesProdutos(newPreviewImages);
            } else {
                setExclusao(false);
            }
        }
    }, [selectedFilesProdutos]);

    useEffect(() => {
        if (selectedFilesAntes.length) {
            const newPreviewImages = selectedFilesAntes.map(file => ({
                file,
                url: URL.createObjectURL(file)
            })
            );
            if (!exclusao) {
                setPreviewImagesAntes(newPreviewImages);
            } else {
                setExclusao(false);
            }
        }
    }, [selectedFilesAntes]);

    useEffect(() => {
        async function get() {
            const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/RS/distritos');
            const nomeCidades = response.data.map((cidade) => {
                return cidade.nome;
            });

            setCidades(nomeCidades.sort());
        }

        get();
    }, [])

    function handleDeleteImage(imagem, previwField, setPreviewImages, selectedFiles, setSelectedFiles) {
        setExclusao(true);
        const newImages = previwField.filter(({ url }) => url !== imagem.url);
        setPreviewImages(newImages);

        const newFiles = selectedFiles.filter((file) => {
            return !(file.name === imagem.file.name && file.size === imagem.file.size && file.type === imagem.file.type);
        });
        setSelectedFiles(newFiles);
    }

    function camposObrigatoriosNaoPreenchidos() {
        const camposObrigatorios = ['empresa', 'cidade', 'segmento', "proposta_negocio", "endereco", "danos", "necessidades", "responsavel", "telefone", "email"]; // Defina os campos obrigatórios aqui
        for (let campo of camposObrigatorios) {
            if (!formData[campo] || formData[campo].trim() === '') {
                return { has: true, message: `O campo ${campo} é obrigatório.` };
            }
        }
        return false;
    }

    async function getImageData(files) {
        try {
            if (files?.length > 0) {
                const promises = files.map(file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve({
                            nome: file.name,
                            base64: reader.result
                        });
                        reader.onerror = () => reject(reader.error);
                        reader.readAsDataURL(file);
                    });
                });
                return await Promise.all(promises);
            }
            return null;
        } catch (error) {
            console.error("Erro ao converter imagens para Base64:", error);
            throw error;
        }
    }

    async function cadastrarEmpresa() {
        try {
            const camposObrigatorios = camposObrigatoriosNaoPreenchidos();

            if (camposObrigatorios.has) {
                return alert(camposObrigatorios.message);
            }

            setLoading(true);
            if (selectedFilesProdutos.length > 0 || selectedFilesAntes.length > 0) {
                if (selectedFilesProdutos.length > 0) {
                    const base64array = await getImageData(selectedFilesProdutos)
                    formData.imagensNegociosUpload = base64array;
                };
                if (selectedFilesAntes.length > 0) {
                    const base64array = await getImageData(selectedFilesAntes)
                    formData.imagensAntesUpload = base64array;
                };
                formData.imagens_antes = "";
                formData.imagens_produto = "";
            }

            const response = await axiosInstance.post("/empresas", formData);
            if (response.status === 201) {
                alert("Empresa cadastrada com sucesso!");
                zerarFormulario();
            } else {
                alert("Erro ao cadastrar empresa. Por favor, tente novamente mais tarde.");
            }

        } catch (error) {
            console.error("Erro ao cadastrar empresa:", error);
            alert("Erro ao cadastrar empresa. Por favor, tente novamente mais tarde.");
        } finally {
            setLoading(false);
        }

    }

    function zerarFormulario() {
        for (let key in formData) {
            formData[key] = '';
        }
        setSelectedFilesAntes([]);
        setSelectedFilesProdutos([]);
        setPreviewImagesAntes([]);
        setPreviewImagesProdutos([]);
        setNecessidades({
            doacoes_dinheiro: false,
            mao_de_obra: false,
            doacoes_materiais: false,
            alcancar_clientes: false
        });
    }

    function toggleNecessidades({ target }) {
        const { name, value } = target;
        setNecessidades({ ...necessidades, [name]: !necessidades[name] });

        let necessidadesArray = formData.necessidades.split(', ').filter(n => n); // Converte para array e remove strings vazias

        if (necessidadesArray.includes(value)) {
            necessidadesArray = necessidadesArray.filter(n => n !== value);
        } else {
            necessidadesArray.push(value);
        }
        setFormData(prevFormData => ({
            ...prevFormData,
            necessidades: necessidadesArray.join(', ')
        }));

    }

    function handleFileChange(event) {
        const selectedFiles = event.target.files;
        let maxFiles;
        const MAX_SIZE_MB = 5;
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            const fileSizeMB = files[i].size / 1024 / 1024; // Converte bytes em MB
            if (fileSizeMB > MAX_SIZE_MB) {
                alert(`O arquivo ${files[i].name} excede o limite de ${MAX_SIZE_MB} MB.`);
                event.target.value = '';
            }
        }
        if (event.target.name === 'imagensProduto') {
            maxFiles = 5 - formData.imagens_produto.length;
        } else if (event.target.name === 'imagensAntes') {
            maxFiles = 5 - formData.imagens_antes.length;
        }
        if (selectedFiles.length > maxFiles) {
            alert(`Você pode selecionar no máximo ${maxFiles} imagens.`);
            event.target.value = '';
        } else {
            if (event.target.name === 'imagensProduto') {
                setSelectedFilesProdutos([...event.target.files]);
            } else {
                setSelectedFilesAntes([...event.target.files]);
            }
        }
    }

    function carregarNovaImagem({ target }) {
        if (target.className.includes("imagem_negocio")) {
            let input = document.querySelector('input[name="imagensProduto"]');
            input.click();
        } else if (target.className.includes("imagem_antes")) {
            let input = document.querySelector('input[name="imagensAntes"]');
            input.click();
        }

    }

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    return (
        <section className={style.container}>
            <div className={style.internal_container}>
                <div className={style.title}>
                    <h1>Dados Gerais da Empresa</h1>
                    <ButtonBarRS />
                </div>

                <div className={`${style.forms} ${style.div_busines_data}`}>
                    <div className={`${style.div_input_text} ${style.a1}`}>
                        <p>Nome da Empresa</p>
                        <input type="text" name="empresa" value={formData.empresa} onChange={handleChange} className={style.input} maxLength="40" />
                    </div>
                    <div className={`${style.div_input_text} ${style.a2}`}>
                        <p>CNPJ da Empresa</p>
                        <input type="text" name="cnpj" value={formData.cnpj} onChange={handleChange} pattern="^\d{2}\.\d{3}\.\d{3}/\d{4}-\d{2}$" title="Insira o CNPJ no formato XX.XXX.XXX/XXXX-XX." className={style.input} />
                    </div>
                    <div className={`${style.div_input_text} ${style.a3}`}>
                        <p>Nome do Responsável</p>
                        <input type="text" name="responsavel" value={formData.responsavel} onChange={handleChange} className={style.input} />
                    </div>
                    <div className={`${style.div_input_text} ${style.a4}`}>
                        <p>CPF do Responsável</p>
                        <input type="text" name="cpf" value={formData.cpf} onChange={handleChange} className={style.input} />
                    </div>
                    <div className={`${style.div_input_text} ${style.a5}`}>
                        <p>Seu negócio é de qual cidade gaúcha?</p>
                        <select list="cidades" name="cidade" value={formData.cidade} onChange={handleChange} className={style.input} >
                            <option value="">Selecione sua cidade</option>
                            {cidades.map((cidade, index) => (
                                <option key={index} value={cidade}>{cidade}</option>
                            ))}
                        </select>
                    </div>
                    <div className={`${style.div_input_text} ${style.a6}`}>
                        <p>Segmento</p>
                        <select type="text" name="segmento" value={formData.segmento} onChange={handleChange} list="segmentosList" className={style.input}>
                            <option value="">Selecione seu segmento</option>
                            <option value="Alimentação">Alimentação</option>
                            <option value="Animais">Animais</option>
                            <option value="Artesanato">Artesanato</option>
                            <option value="Automotivo">Automotivo</option>
                            <option value="Bazar">Bazar</option>
                            <option value="Beleza">Beleza</option>
                            <option value="Brinquedos">Brinquedos</option>
                            <option value="Construção">Construção</option>
                            <option value="Consultoria">Consultoria</option>
                            <option value="Cursos">Cursos</option>
                            <option value="Decoração">Decoração</option>
                            <option value="Esporte">Esporte</option>
                            <option value="Eventos">Eventos</option>
                            <option value="Ferramentas">Ferramentas</option>
                            <option value="Games">Games</option>
                            <option value="Hospedagem">Hospedagem</option>
                            <option value="Informática">Informática</option>
                            <option value="Insumos">Insumos</option>
                            <option value="Mercado">Mercado</option>
                            <option value="Moda">Moda</option>
                            <option value="Móveis">Móveis</option>
                            <option value="Música">Música</option>
                            <option value="Ótica">Ótica</option>
                            <option value="Saúde">Saúde</option>
                            <option value="Serviços">Serviços</option>
                            <option value="Sex Shop">Sex Shop</option>
                        </select>
                    </div>
                    <div className={`${style.div_input_text} ${style.a7}`}>
                        <p>Descreva o seu produto/serviço:</p>
                        <input type="text" className={style.input} name="proposta_negocio" value={formData.proposta_negocio} onChange={handleChange} maxLength="150" />
                    </div>
                    <div className={`${style.div_input_text} ${style.a8}`}>
                        <p>Anexe até 5 fotos do seu produto/serviço:</p>
                        <div className={style.upload_btn_wrapper}>
                            <button type="button" className={`${style.btn} ${"imagem_negocio"}`} onClick={carregarNovaImagem}>Selecionar imagens</button>
                            <input name="imagensProduto" type="file" accept="image/*" hidden multiple onChange={handleFileChange} />
                        </div>
                        <div className={style.div_bind_images}>
                            {
                                previewImagesProdutos.map((imagem) => (
                                    <div key={imagem.url} className={style.container_image_button}>
                                        <div className={style.preview}>
                                            <ImageWithFallback src={imagem.url} alt="Imagem do seu negócio" style={style} />
                                        </div>
                                        <button className={style.delete_image} onClick={() => { handleDeleteImage(imagem, previewImagesProdutos, setPreviewImagesProdutos, selectedFilesProdutos, setSelectedFilesProdutos); }}>Excluir</button>
                                    </div>
                                ))}
                            {
                                previewImagesProdutos.length > 0 &&
                                Array.from({ length: 5 - formData.imagens_produto.length - previewImagesProdutos.length }, (_, index) => (
                                    <div key={`placeholder-${index}`} className={`${style.preview}`}></div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className={style.title}>
                    <h1>Como sua empresa foi afetada</h1>
                    <ButtonBarRS />
                </div>

                <div className={`${style.forms} ${style.div_afected}`}>
                    <div className={style.div_input_text}>
                        <p>Nos conte como você foi impactado:</p>
                        <textarea className={style.input_textarea} name="danos" value={formData.danos} onChange={handleChange} maxLength="250" />
                    </div>
                    <div className={`${style.div_input_text}`}>
                        <p>Como podemos te ajudar? (Pode marcar mais de uma opção...)</p>
                        <div className={`${style.div_input_type_help}`}>
                            <label className={style.div_option}>
                                <input type="checkbox" name="doacoes_dinheiro" value="Doações em dinheiro" checked={necessidades.doacoes_dinheiro} onChange={toggleNecessidades} />
                                <p>Doações em dinheiro</p>
                            </label>
                            <label className={style.div_option}>
                                <input type="checkbox" name="mao_de_obra" value="Mão de obra" checked={necessidades.mao_de_obra} onChange={toggleNecessidades} />
                                <p>Mão de obra</p>
                            </label>
                            <label className={style.div_option}>
                                <input type="checkbox" name="doacoes_materiais" value="Doações de materiais" checked={necessidades.doacoes_materiais} onChange={toggleNecessidades} />
                                <p>Doações de materiais</p>
                            </label>
                            <label className={style.div_option}>
                                <input type="checkbox" name="alcancar_clientes" value="Alcançar mais clientes" checked={necessidades.alcancar_clientes} onChange={toggleNecessidades} />
                                <p>Alcançar mais clientes</p>
                            </label>
                        </div>
                    </div>
                    <div className={style.div_input_text}>
                        <p>Caso precise de doações em dinheiro, qual a chave pix ou vakinha?</p>
                        <input type="text" name="pix" value={formData.pix} className={style.input} onChange={handleChange} />
                    </div>
                    <div className={style.div_input_text}>
                        <p>Caso precise de doações de utensílios/matéria-prima/móveis/outros, liste abaixo os itens:</p>
                        <input type="text" name="itens_doacao" value={formData.itens_doacao} className={style.input} onChange={handleChange} />
                    </div>
                    <div className={style.div_input_text}>
                        <p>Se possível, adicione fotos de antes e depois das enchentes:</p>
                        <div className={style.upload_btn_wrapper}>
                            <button type="button" className={`${style.btn} ${"imagem_antes"}`} onClick={carregarNovaImagem}>Selecionar imagens</button>
                            <input type="file" name="imagensAntes" accept="image/*" multiple hidden onChange={handleFileChange} />
                        </div>
                        <div className={style.div_bind_images}>
                            {
                                previewImagesAntes.map((imagem) =>
                                (
                                    <div key={imagem.url} className={style.container_image_button}>
                                        <div className={style.preview}>
                                            <ImageWithFallback src={imagem.url} alt="Imagem depois da enchente" style={style} />
                                        </div>
                                        <button className={style.delete_image} onClick={() => { handleDeleteImage(imagem, previewImagesAntes, setPreviewImagesAntes, selectedFilesAntes, setSelectedFilesAntes); }}>Excluir</button>
                                    </div>
                                ))}
                            {
                                previewImagesAntes.length > 0 &&
                                Array.from({ length: 5 - formData.imagens_antes.length - previewImagesAntes.length }, (_, index) => (
                                    <div key={`placeholder-${index}`} className={`${style.preview}`}></div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className={style.title}>
                    <h1>Informações de Contato</h1>
                    <ButtonBarRS />
                </div>

                <div className={`${style.forms} ${style.div_contact}`}>
                    <div className={style.div_input_text}>
                        <p>E-mail</p>
                        <input type="email" value={formData.email} name="email" onChange={handleChange} className={style.input} />
                    </div>
                    <div className={style.div_input_text}>
                        <p>Telefone</p>
                        <input type="tel" value={formData.telefone} name="telefone" onChange={handleChange} className={style.input} />
                    </div>
                    <div className={style.div_input_text}>
                        <p>Endereço do empreendimento: (Caso seja apenas virtual, digite “virtual”)</p>
                        <span className={style.obs}>Endereço completo incluindo bairro/cidade</span>
                        <input type="text" value={formData.endereco} name="endereco" onChange={handleChange} className={style.input} />
                    </div>
                    <div className={style.div_input_text}>
                        <p>Instagram:</p>
                        <input type="text" value={formData.instagram} name="instagram" onChange={handleChange} className={style.input} />
                    </div>
                    <div className={style.div_input_text}>
                        <p>Site:</p>
                        <input type="url" value={formData.link_site} name="link_site" onChange={handleChange} className={style.input} />
                    </div>
                </div>

                <p className={style.privacy}>Ao enviar o formulário você concorda em receber contato para oferecimento de possível consultoria gratuita e com nossa <button onClick={() => setLgpd(true)}>política de privacidade de dados</button>.</p>
                <div className={style.button_enviar} onClick={cadastrarEmpresa}>
                    <h1>ENVIAR</h1>
                    <ButtonBarRS fontSize={18} />
                </div>
            </div>
            {lgpd &&
                <div className={style.container_lgpd}>
                    <p>
                        Política de Privacidade de Dados
                    </p>
                    <p>
                        Introdução
                    </p>
                    <p>
                        A SOS Negócios Gaúchos respeita a privacidade de seus usuários e está comprometida em proteger os dados pessoais que coletamos. Esta Política de Privacidade explica como coletamos, usamos, armazenamos e compartilhamos seus dados pessoais, conforme previsto na Lei Geral de Proteção de Dados (LGPD).
                    </p>
                    Coleta de Dados Pessoais
                    <p>
                        Ao se cadastrar na plataforma SOS Negócios Gaúchos, coletamos os seguintes dados pessoais:
                    </p>
                    <p>
                        - Nome do responsável pelo negócio
                        - Nome do negócio
                        - Endereço do negócio
                        - Categoria do negócio
                        - Informações de contato (telefone, email, site e instagram)
                        - Descrição do negócio
                        - Imagens do negócio
                        - CNPJ do negócio
                        - CPF do responsável
                        - Relato de impacto após enchentes
                    </p>
                    <p>
                        Uso dos Dados Pessoais
                    </p>
                    <p>
                        Os dados coletados serão utilizados para:
                    </p>
                    <p>
                        - Cadastro e exibição do seu negócio na plataforma SOS Negócios Gaúchos.
                        - Envio dos dados para o projeto Salva RS, que poderá entrar em contato oferecendo consultorias gratuitas para os negócios cadastrados.
                    </p>
                    <p>
                        Compartilhamento de Dados
                    </p>
                    <p>
                        Seus dados serão compartilhados exclusivamente com o projeto Salva RS para fins de suporte e consultoria gratuita aos negócios afetados pelas enchentes no Rio Grande do Sul. Não venderemos, alugaremos ou compartilharemos seus dados pessoais com terceiros para fins de marketing sem seu consentimento explícito.
                    </p>
                    <p>
                        Direitos dos Usuários
                    </p>
                    <p>
                        Conforme a LGPD, você tem o direito de:
                    </p>
                    <p>
                        - Acessar seus dados pessoais.
                        - Solicitar a correção de dados incompletos, inexatos ou desatualizados.
                        - Solicitar a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a lei.
                        - Solicitar a portabilidade dos dados a outro fornecedor de serviço ou produto.
                        - Solicitar a eliminação dos dados pessoais tratados com seu consentimento.
                        - Obter informações sobre as entidades públicas ou privadas com as quais compartilhamos seus dados.
                        - Informar-se sobre a possibilidade de não fornecer consentimento e as consequências dessa negativa.
                        - Revogar o consentimento.
                    </p>
                    <p>
                        Para exercer seus direitos, entre em contato conosco através dos contatos disponibilizados no rodapé do site.
                    </p>
                    <p>
                        Segurança dos Dados
                    </p>
                    <p>
                        Implementamos medidas de segurança técnicas e organizacionais adequadas para proteger seus dados pessoais contra acesso não autorizado, perda, destruição ou alteração.
                    </p>
                    <p>
                        Retenção de Dados
                    </p>
                    <p>
                        Reteremos seus dados pessoais pelo tempo necessário para cumprir as finalidades para as quais os coletamos, incluindo a conformidade com requisitos legais, contábeis ou de relatórios.
                    </p>
                    <p>
                        Alterações na Política de Privacidade
                    </p>
                    <p>
                        - Podemos atualizar esta Política de Privacidade periodicamente.
                        - Informaremos você sobre qualquer mudança significativa publicando a nova política em nosso site.
                        - Recomendamos que você revise esta página regularmente para se manter informado sobre como estamos protegendo seus dados pessoais.
                    </p>
                    <p>
                        Contato
                    </p>
                    <p>
                        Se você tiver alguma dúvida sobre esta Política de Privacidade ou desejar exercer seus direitos relativos à proteção de dados pessoais, entre em contato conosco através do email adm@sosnegociosgauchos.com.
                    </p>
                    <p>
                        Data de Criação: 02 de Junho de 2024.
                    </p>
                    <p>
                    </p>
                    <div className={style.button_entendi} onClick={() => setLgpd(false)}>
                        <h4>Fechar</h4>
                    </div>
                </div>
            }

            {
                loading && <Loading />
            }
        </section >
    );
}