const getUserLocal = () => {
    let user = localStorage.getItem('user')
    return user ? JSON.parse(user) : null;
};

const setUserLocal = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

const removeUserLocal = () => {
    localStorage.removeItem('user');
};


export { getUserLocal, setUserLocal, removeUserLocal };
