import React, { useEffect, useState } from "react";
import style from "../../assets/styles/Companies/AdmCompany.module.css";

import axiosInstance from "../../axios/config";

import mais from "../../assets/images/mais.png";
import menos from "../../assets/images/menos.png";
import publicada from "../../assets/images/verificado.png";

import ButtonBarRS from "../Utils/ButtonBarRS";
import ImageWithFallback from "../Utils/ImageWithFallback";
import ConfirmOperation from "../Utils/ConfirmOperation";
import Loading from "../Utils/Loading";

export default function CompanyAdministrator({ empresa, reLoad }) {

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(empresa);
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const [confirm, setConfirm] = useState(false);
    const [imagemExcluir, setImagemExcluir] = useState('');

    const [camposEditados, setCamposEditados] = useState({});

    const [necessidades, setNecessidades] = useState({
        doacoes_dinheiro: false,
        mao_de_obra: false,
        doacoes_materiais: false,
        alcancar_clientes: false
    });

    const [selectedFilesProdutos, setSelectedFilesProdutos] = useState([]);
    const [previewImagesProdutos, setPreviewImagesProdutos] = useState([]);
    const [selectedFilesAntes, setSelectedFilesAntes] = useState([]);
    const [previewImagesAntes, setPreviewImagesAntes] = useState([]);

    useEffect(() => {
        if (selectedFilesProdutos.length) {
            const newPreviewImages = selectedFilesProdutos.map(file =>
                URL.createObjectURL(file)
            );
            setPreviewImagesProdutos(newPreviewImages);
        }
    }, [selectedFilesProdutos]);

    useEffect(() => {
        if (selectedFilesAntes.length) {
            const newPreviewImages = selectedFilesAntes.map(file =>
                URL.createObjectURL(file)
            );
            setPreviewImagesAntes(newPreviewImages);
        }
    }, [selectedFilesAntes]);

    useEffect(() => {
        setFormData(updateFormData());
    }, [empresa])

    useEffect(() => {
        setCamposEditados(verificarCamposEditados());
    }, [formData]);

    useEffect(() => {
        async function excluirImagem() {
            let imagensEmString = camposEditados[imagemExcluir.campo].join(', ');
            const response = await axiosInstance.put(`/empresas/${empresa.id}`, {
                data: {
                    [imagemExcluir.campo]: imagensEmString
                }
            });
            setConfirm(false);
            setImagemExcluir("");

            reLoad();
            return alert(response.data.mensagem);
        }
        try {
            setLoading(true);
            if (imagemExcluir && confirm) excluirImagem();
        } catch (err) {
            alert("Erro ao excluir a imagem.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [camposEditados]);

    useEffect(() => {
        let novasNecessidades = necessidades;
        if (formData.necessidades.includes("Doações em dinheiro")) {
            novasNecessidades.doacoes_dinheiro = true;
        }
        if (formData.necessidades.includes("Alcançar mais clientes")) {
            novasNecessidades.alcancar_clientes = true;
        }
        if (formData.necessidades.includes("Mão de obra")) {
            novasNecessidades.mao_de_obra = true;
        }
        if (formData.necessidades.includes("Doações de materiais")) {
            novasNecessidades.doacoes_materiais = true;
        }
        setNecessidades(novasNecessidades);
    }, [open])

    function updateFormData() {
        let updatedFormData = { ...formData };
        for (let key in updatedFormData) {
            if (key === "imagens_produto" || key === "imagens_antes") continue;
            if (updatedFormData[key] == null) {
                updatedFormData[key] = '';
            }
        }

        updatedFormData.imagens_produto = updatedFormData.imagens_produto ? updatedFormData.imagens_produto : [];
        updatedFormData.imagens_antes = updatedFormData.imagens_antes ? updatedFormData.imagens_antes : [];

        return updatedFormData;
    }

    function toggleDisable() {
        setIsDisabled(!isDisabled);
    }

    function toggleNecessidades({ target }) {
        const { name, value } = target;
        setNecessidades({ ...necessidades, [name]: !necessidades[name] });

        let necessidadesArray = formData.necessidades.split(', ');
        necessidadesArray = necessidadesArray.map(nece => {
            return nece.trim();
        })
        let novasNecessidades;

        if (necessidadesArray.includes(value)) {
            novasNecessidades = necessidadesArray.filter(necessidade => necessidade !== value);
        } else {
            novasNecessidades = [...necessidadesArray, value];
        }

        setFormData({ ...formData, necessidades: novasNecessidades.join(', ') });
    }

    function verificarCamposEditados() {
        let camposEditados = {};
        for (let key in formData) {
            if (key === "updatedAt") continue;
            if (key === "imagens_produto" || key === "imagens_antes") {
                if (formData[key].length !== empresa[key].length) {
                    camposEditados[key] = formData[key];
                }
            } else if (formData[key] !== empresa[key] && empresa[key] !== null) {
                camposEditados[key] = formData[key];
            }
        }
        return camposEditados;
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    async function handleDeleteImage(imageUrl, campo) {
        try {
            setImagemExcluir({ imageUrl, campo })
            setConfirm(true);
        } catch (error) {
            alert('Erro ao excluir a imagem:', error);
        }
    }

    async function downloadImage(imageUrl) {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) throw new Error('Não foi possível baixar a imagem.');
            const imageBlob = await response.blob();
            const imageURL = URL.createObjectURL(imageBlob);

            const link = document.createElement('a');
            link.href = imageURL;
            link.download = `${imageUrl}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(imageURL);
        } catch (error) {
            console.error('Erro ao baixar a imagem:', error);
        }
    }

    async function salvarEdicao() {
        try {
            setLoading(true);
            const camposEditados = verificarCamposEditados();
            if (selectedFilesProdutos.length > 0 || selectedFilesAntes.length > 0) {
                if (selectedFilesProdutos.length > 0) {
                    camposEditados.imagensNegociosUpload = await getImageData(selectedFilesProdutos)
                    camposEditados.imagens_produto = [...formData.imagens_produto];
                };
                if (selectedFilesAntes.length > 0) {
                    camposEditados.imagensAntesUpload = await getImageData(selectedFilesAntes)
                    camposEditados.imagens_antes = [...formData.imagens_antes];
                };
            }
            if (Object.keys(camposEditados).length > 0) {
                let imagensProdutoEmString = camposEditados["imagens_produto"] ? camposEditados["imagens_produto"].join(', ') : null;
                let imagensAntesEmString = camposEditados["imagens_antes"] ? camposEditados["imagens_antes"].join(', ') : null;
                if (imagensProdutoEmString) camposEditados["imagens_produto"] = imagensProdutoEmString;
                if (imagensAntesEmString) camposEditados["imagens_antes"] = imagensAntesEmString;

                const response = await axiosInstance.put(`/empresas/${empresa.id}`, { data: camposEditados });
                setFormData(await { ...response.data.empresa });

                setSelectedFilesProdutos([])
                setPreviewImagesProdutos([])
                setSelectedFilesAntes([])
                setPreviewImagesAntes([])

                reLoad();
                return alert("Empresa atualizada com sucesso.");
            }
            return alert("Nada para editar.");
        } catch (error) {
            if (error.message.includes("request entity too large")) {
                alert("O tamanho do arquivo excede o limite permitido. Por favor, selecione um arquivo menor.");
            }
            return console.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getImageData(files) {
        try {
            if (files?.length > 0) {
                const promises = files.map(file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve({
                            nome: file.name,
                            base64: reader.result
                        });
                        reader.onerror = () => reject(reader.error);
                        reader.readAsDataURL(file);
                    });
                });
                return await Promise.all(promises);
            }
            return null;
        } catch (error) {
            alert("Erro ao converter imagens para Base64.");
            console.error("Erro ao converter imagens para Base64:", error);
        }
    }

    function carregarNovaImagem(event) {
        event = event.target.tagName !== "DIV" ? event.target.parentElement : event.target;
        if (event.className.includes("imagem_negocio")) {
            let input = document.querySelector('input[name="imagensProduto"]');
            input.click();
        } else if (event.className.includes("imagem_antes")) {
            let input = document.querySelector('input[name="imagensAntes"]');
            input.click();
        }
    }

    function handleFileChange(event) {
        const selectedFiles = event.target.files;
        let maxFiles;
        const MAX_SIZE_MB = 2;
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            const fileSizeMB = files[i].size / 1024 / 1024; // Converte bytes em MB
            if (fileSizeMB > MAX_SIZE_MB) {
                alert(`O arquivo ${files[i].name} excede o limite de ${MAX_SIZE_MB} MB.`);
                event.target.value = '';
            }
        }
        if (event.target.name === 'imagensProduto') {
            maxFiles = 5 - formData.imagens_produto.length;
        } else if (event.target.name === 'imagensAntes') {
            maxFiles = 5 - formData.imagens_antes.length;
        }
        if (selectedFiles.length > maxFiles) {
            alert(`Você pode selecionar no máximo ${maxFiles} imagens.`);
            event.target.value = '';
        } else {
            if (event.target.name === 'imagensProduto') {
                setSelectedFilesProdutos([...event.target.files]);
            } else {
                setSelectedFilesAntes([...event.target.files]);
            }
        }
    }

    async function toConfirm() {
        try {
            let imagens = formData[imagemExcluir.campo].filter(imagem => imagem !== imagemExcluir.imageUrl);
            setFormData({ ...formData, [imagemExcluir.campo]: imagens });
        } catch (error) {
            console.error('Erro ao excluir a imagem:', error);
        }
    }

    function toRefuse() {
        setImagemExcluir('');
        setConfirm(false);
    }

    async function publicar() {
        try {
            setLoading(true);
            const response = await axiosInstance.put(`/empresas/${empresa.id}`, { data: { publicado: true } });
            setFormData(await { ...response.data.empresa });
            reLoad();
            return alert("Empresa publicada com sucesso.");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function despublicar() {
        try {
            setLoading(true);
            const response = await axiosInstance.put(`/empresas/${empresa.id}`, { data: { publicado: false } });
            setFormData(await { ...response.data.empresa });
            reLoad();
            return alert("Empresa despublicada com sucesso.");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={`${style.editar_empresa}  ${open ? style.expandida : ""}`}>
            <div onClick={() => setOpen(!open)} className={`${style.div_empresa_reduzida} ${open ? style.empresa_open : style.empresa_close}`}>
                <p className={style.nome}>{
                    !open ? formData["empresa"].slice(0, 20) + (formData["empresa"].length > 20 ? "..." : "") : formData["empresa"]
                }</p>
                <p className={style.data}>{formData["data_criacao"]}</p>
                <div className={style.div_publicada_abrir}>
                    {
                        formData["publicado"] && <img className={style.publicada} src={publicada} alt="Empresa publicada" />
                    }
                    <img className={style.icone_abrir} src={open ? menos : mais} alt="Abrir empresa" />
                </div>
            </div>
            {open &&
                <div className={style.empresa} >
                    <div className={`${style.forms} ${style.div_busines_data}`}>
                        <div className={`${style.div_input_text} ${style.a1}`}>
                            <p>Nome da Empresa</p>
                            <input type="text" name="empresa" className={style.input} disabled={isDisabled} value={formData.empresa} onChange={(e) => handleChange(e, 'empresa')} />
                        </div>
                        <div className={`${style.div_input_text} ${style.a2}`}>
                            <p>CNPJ da Empresa</p>
                            <input type="text" name="cnpj" pattern="^\d{2}\.\d{3}\.\d{3}/\d{4}-\d{2}$" title="Insira o CNPJ no formato XX.XXX.XXX/XXXX-XX." className={style.input} disabled={isDisabled} value={formData.cnpj} onChange={(e) => handleChange(e, 'cnpj')} />
                        </div>
                        <div className={`${style.div_input_text} ${style.a3}`}>
                            <p>Nome do Responsável</p>
                            <input type="text" name="responsavel" className={style.input} disabled={isDisabled} value={formData.responsavel} onChange={(e) => handleChange(e, 'responsavel')} />
                        </div>
                        <div className={`${style.div_input_text} ${style.a4}`}>
                            <p>CPF do Responsável</p>
                            <input type="text" name="cpf" className={style.input} disabled={isDisabled} value={formData.cpf} onChange={(e) => handleChange(e, 'cpf')} />
                        </div>
                        <div className={`${style.div_input_text} ${style.a5}`}>
                            <p>Seu negócio é de qual cidade gaúcha?</p>
                            <input type="text" name="cidade" className={style.input} disabled={isDisabled} value={formData.cidade} onChange={(e) => handleChange(e, 'cidade')} />
                        </div>
                        <div className={`${style.div_input_text} ${style.a6}`}>
                            <p>Segmento</p>
                            <input type="text" name="segmento" list="segmentosList" className={style.input} disabled={isDisabled} value={formData.segmento} onChange={(e) => handleChange(e, 'segmento')} />
                            <datalist id="segmentosList">
                                <option value="Alimentação"></option>
                                <option value="Animais"></option>
                                <option value="Artesanato"></option>
                                <option value="Automotivo"></option>
                                <option value="Bazar"></option>
                                <option value="Beleza"></option>
                                <option value="Brinquedos"></option>
                                <option value="Construção"></option>
                                <option value="Consultoria"></option>
                                <option value="Cursos"></option>
                                <option value="Decoração"></option>
                                <option value="Esporte"></option>
                                <option value="Eventos"></option>
                                <option value="Ferramentas"></option>
                                <option value="Games"></option>
                                <option value="Hospedagem"></option>
                                <option value="Informática"></option>
                                <option value="Insumos"></option>
                                <option value="Mercado"></option>
                                <option value="Moda"></option>
                                <option value="Móveis"></option>
                                <option value="Música"></option>
                                <option value="Ótica"></option>
                                <option value="Saúde"></option>
                                <option value="Serviços"></option>
                                <option value="Sex Shop"></option>
                            </datalist>
                        </div>
                        <div className={`${style.div_input_text} ${style.a7}`}>
                            <p>Descreva o seu produto/serviço:</p>
                            <input type="text" name="proposta_negocio" className={style.input} disabled={isDisabled} value={formData.proposta_negocio} onChange={(e) => handleChange(e, 'proposta_negocio')} />
                        </div>
                        <div className={`${style.div_input_text} ${style.a8}`}>
                            <p>Anexe até 5 fotos do seu produto/serviço:</p>
                            <input type="file" name="imagensProduto" accept="image/*" multiple hidden disabled={isDisabled} onChange={handleFileChange} />
                            <div className={style.div_bind_images}>
                                {
                                    formData.imagens_produto.map((imagem, index) => (
                                        <div key={imagem} className={style.div_images_button}>
                                            <div className={style.preview}>
                                                <ImageWithFallback src={imagem} alt="Imagem do seu negócio" style={style} />
                                            </div>
                                            <div className={style.div_buttons_images}>
                                                <button className={`${style.button_action} ${style.download}`} onClick={() => downloadImage(imagem)}>Download</button>
                                                <button className={`${style.button_action} ${style.delete}`} disabled={isDisabled} onClick={() => handleDeleteImage(imagem, "imagens_produto")}>Excluir</button>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    previewImagesProdutos.map((imagem) => (
                                        <div key={imagem} className={style.preview}>
                                            <ImageWithFallback src={imagem} alt="Imagem do seu negócio" style={style} />
                                        </div>
                                    ))}
                                {
                                    Array.from({ length: 5 - formData.imagens_produto.length - previewImagesProdutos.length }, (_, index) => (
                                        <div onClick={carregarNovaImagem} key={`placeholder-${index}`} className={`${style.preview} ${style.sem_imagem} ${"imagem_negocio"} ${isDisabled ? style.desativado : style.ativado}`}>
                                            <img className={style.image_plus} src={mais} alt="Imagem do seu negócio" />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${style.forms} ${style.div_afected}`}>
                        <div className={style.div_input_text}>
                            <p>Nos conte como você foi impactado:</p>
                            <textarea name="danos" className={style.input_textarea} disabled={isDisabled} value={formData.danos} onChange={(e) => handleChange(e, 'danos')} />
                        </div>
                        <div className={`${style.div_input_text}`}>
                            <p>Como podemos te ajudar? (Pode marcar mais de uma opção...)</p>
                            <p>Texto da cadastrante: {formData.necessidades}</p>
                            <div className={`${style.div_input_type_help}`}>
                                <label className={style.div_option}>
                                    <input type="checkbox" name="doacoes_dinheiro" value="Doações em dinheiro" checked={necessidades.doacoes_dinheiro} onChange={toggleNecessidades} disabled={isDisabled} />
                                    <p>Doações em dinheiro</p>
                                </label>
                                <label className={style.div_option}>
                                    <input type="checkbox" name="mao_de_obra" value="Mão de obra" checked={necessidades.mao_de_obra} onChange={toggleNecessidades} disabled={isDisabled} />
                                    <p>Mão de obra</p>
                                </label>
                                <label className={style.div_option}>
                                    <input type="checkbox" name="doacoes_materiais" value="Doações de materiais" checked={necessidades.doacoes_materiais} onChange={toggleNecessidades} disabled={isDisabled} />
                                    <p>Doações de materiais</p>
                                </label>
                                <label className={style.div_option}>
                                    <input type="checkbox" name="alcancar_clientes" value="Alcançar mais clientes" checked={necessidades.alcancar_clientes} onChange={toggleNecessidades} disabled={isDisabled} />
                                    <p>Alcançar mais clientes</p>
                                </label>
                            </div>
                        </div>
                        <div className={style.div_input_text}>
                            <p>Caso precise de doações em dinheiro, qual a chave pix ou vakinha?</p>
                            <input type="text" name="pix" className={style.input} disabled={isDisabled} value={formData.pix} onChange={(e) => handleChange(e, 'pix')} />
                        </div>
                        <div className={style.div_input_text}>
                            <p>Caso precise de doações de utensílios/matéria-prima/móveis/outros, liste abaixo os itens:</p>
                            <input type="text" name="itens_doacao" className={style.input} disabled={isDisabled} value={formData.itens_doacao} onChange={(e) => handleChange(e, 'itens_doacao')} />
                        </div>
                        <div className={style.div_input_text}>
                            <p>Se possível, adicione fotos de antes e depois das enchentes:</p>
                            <input type="file" name="imagensAntes" accept="image/*" multiple hidden disabled={isDisabled} onChange={handleFileChange} />
                            <div className={style.div_bind_images}>
                                {
                                    formData.imagens_antes.map((imagem, index) => (
                                        <div key={imagem} className={style.div_images_button}>
                                            <div className={style.preview}>
                                                <ImageWithFallback src={imagem} alt="Imagem do seu negócio" style={style} />
                                            </div>
                                            <div className={style.div_buttons_images}>
                                                <button className={`${style.button_action} ${style.download}`} disabled={isDisabled} onClick={() => downloadImage(imagem)}>Download</button>
                                                <button className={`${style.button_action} ${style.delete}`} disabled={isDisabled} onClick={() => handleDeleteImage(imagem, "imagens_antes")}>Excluir</button>
                                            </div>
                                        </div>
                                    ))
                                }
                                {previewImagesAntes.map((imagem) => (
                                    <div key={imagem} className={style.preview}>
                                        <ImageWithFallback src={imagem} alt="Imagem do seu negócio" style={style} />
                                    </div>
                                ))}
                                {
                                    Array.from({ length: 5 - formData.imagens_antes.length - previewImagesAntes.length }, (_, index) => (
                                        <div onClick={carregarNovaImagem} key={`placeholder-${index}`} className={`${style.preview} ${style.sem_imagem} ${"imagem_antes"} ${isDisabled ? style.desativado : style.ativado}`}>
                                            <img className={style.image_plus} src={mais} alt="Imagem do seu negócio" />
                                        </div>
                                    ))

                                }
                            </div>
                        </div>
                    </div>


                    <div className={`${style.forms} ${style.div_contact}`}>
                        <div className={style.div_input_text}>
                            <p>Email</p>
                            <input type="email" name="email" className={style.input} value={formData.email} onChange={(e) => handleChange(e)} disabled={isDisabled} />
                        </div>
                        <div className={style.div_input_text}>
                            <p>Telefone</p>
                            <input type="tel" name="telefone" className={style.input} value={formData.telefone} onChange={(e) => handleChange(e)} disabled={isDisabled} />
                        </div>
                        <div className={style.div_input_text}>
                            <p>Endereço do empreendimento: (Caso seja apenas virtual, digite “virtual”)</p>
                            <input type="text" name="endereco" className={style.input} value={formData.endereco} onChange={(e) => handleChange(e)} disabled={isDisabled} />
                        </div>
                        <div className={style.div_input_text}>
                            <p>Instagram:</p>
                            <input type="text" name="instagram" className={style.input} value={formData.instagram} onChange={(e) => handleChange(e)} disabled={isDisabled} />
                        </div>
                        <div className={style.div_input_text}>
                            <p>Site:</p>
                            <input type="url" name="link_site" className={style.input} value={formData.link_site} onChange={(e) => handleChange(e)} disabled={isDisabled} />
                        </div>
                    </div>
                    <div className={style.actions}>
                        <div onClick={toggleDisable} className={style.div_buttons}>
                            <p>Editar</p>
                            <ButtonBarRS />
                        </div>
                        <div onClick={salvarEdicao} className={style.div_buttons}>
                            <p>Salvar</p>
                            <ButtonBarRS />
                        </div>
                        {
                            formData.publicado ?
                                <div onClick={despublicar} className={style.div_buttons}>
                                    <p>Despublicar</p>
                                    <ButtonBarRS />
                                </div>
                                :
                                <div onClick={publicar} className={style.div_buttons}>
                                    <p>Publicar</p>
                                    <ButtonBarRS />
                                </div>
                        }
                    </div>
                </div>
            }

            {
                confirm &&
                <ConfirmOperation mensagem={"Deseja excluir a foto? "} confirm={toConfirm} cancel={toRefuse} />
            }

            {
                loading && Loading
            }
        </div>

    );
}