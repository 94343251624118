import React, { useState, useEffect, useContext } from "react";
import style from "../../assets/styles/Private/Adm.module.css"

import lupa from "../../assets/images/lupa.png";
import excel from "../../assets/images/excel.png"

import AdmCompany from "./AdmCompany";
import Logo from "../Utils/Logo";
import ButtonBarRS from "../Utils/ButtonBarRS";
import DropdownFilter from "../Utils/DropdownFilter";

import axiosInstance from "../../axios/config";
import { FiltersContext } from "../context/FilterContext";
import Loading from "../Utils/Loading";

export default function Adm() {

    const [loading, setLoading] = useState(false);
    const [empresas, setEmpresas] = useState([]);
    const [empresasManipuladas, setEmpresasManipuladas] = useState([]);
    const [search, setSearch] = useState("");

    const [currentContext, setcurrentContext] = useState(false);
    const filters = useContext(FiltersContext);
    const [filtros, setFiltros] = useState({ cidades: [], segmentos: [] });

    useEffect(() => {
        filtrar();
    }, [filters, search])

    useEffect(() => {
        async function fetchEmpresas() {
            try {
                setLoading(true);
                const empresas_buscadas = await getEmpresas();
                setEmpresas(empresas_buscadas);
                setEmpresasManipuladas(empresas_buscadas);

                if (!empresas_buscadas) {
                    throw new Error("Erro ao buscar as empresas");
                }
                const todasCidades = empresas_buscadas.flatMap(empresa => {
                    if (empresa.cidade.includes(",")) {
                        return empresa.cidade.split(",").map(cidade => cidade.trim());
                    } else {
                        return empresa.cidade.trim();
                    }
                }).filter(cidade => cidade.trim() !== "");

                const cidades = [...new Set(todasCidades.sort())];

                const todosSegmentos = empresas_buscadas.map(empresa => empresa.segmento.trim()).filter(segmento => segmento.trim() !== "");
                const segmentos = [...new Set(todosSegmentos.sort())];

                setFiltros({
                    cidades: [...cidades],
                    segmentos: [...segmentos]
                });
            } catch (error) {
                console.log(error.message);
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        fetchEmpresas();

    }, []);

    useEffect(() => {
        filtrar();
    }, [empresas])

    async function filtrar() {
        const empresasFiltradas = empresas.filter(empresa => {
            let passouFiltro = true;
            if (filters.segmentos.values.length > 0) {
                passouFiltro = filters.segmentos.values.includes(empresa.segmento);
            }
            if (filters.cidades.values.length > 0) {
                if (empresa.cidade.includes(",")) {
                    const cidades = empresa.cidade.split(", ").map(cidade => cidade.trim());
                    passouFiltro = passouFiltro && cidades.some(cidade => filters.cidades.values.includes(cidade));
                } else {
                    // Esta linha agora só será executada se a empresa não tiver múltiplas cidades
                    passouFiltro = passouFiltro && filters.cidades.values.includes(empresa.cidade);
                }
            }
            if (filters.publicado.values.length > 0) {
                passouFiltro = passouFiltro && filters.publicado.values.includes(empresa.publicado ? "Sim" : "Não");
            }
            if (search) {
                passouFiltro = passouFiltro && empresa.empresa.toLowerCase().includes(search.toLowerCase());
            }
            return passouFiltro;
        });
        setEmpresasManipuladas(empresasFiltradas);
    }

    async function getEmpresas() {
        try {
            setLoading(true);
            const response = await axiosInstance.get("/empresas/getAll");
            const response_data = response.data;

            let data = response_data.map((empresa) => {
                empresa.data_criacao = formatarData(empresa);
                return transformarLinksImagens(empresa);
            })


            // return data.filter(empresa => empresa.cidade.includes("estrela"));
            return data;
        } catch (error) {
            // TODO colocar aqui um modal de erro para apresnetar o erro ao usuário
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    function formatarData(empresa) {
        if (empresa.createdAt) {
            const date = new Date(empresa.createdAt);
            return date.toLocaleDateString('pt-BR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            });
        }
    }
    // TODO mudar os links direto no banco para não precisar fazer essa alteração
    function transformarLinksImagens(objeto) {
        let objetoAtualizado = { ...objeto };
        if (objeto.imagens_antes) {
            const urls_no_space = objeto.imagens_antes.replace(/ /g, '');
            const urlsAntes = urls_no_space.split(',');
            const novasUrlsAntes = urlsAntes.map(url => {
                const match = url.match(/id=([^&]+)/);
                if (match && match[1]) {
                    return `https://lh3.googleusercontent.com/d/${match[1]}`;
                }
                return url; // Retorna a URL original se não encontrar um ID
            });
            objetoAtualizado.imagens_antes = novasUrlsAntes;
        }

        if (objeto.imagens_produto) {
            const urlsProduto = objeto.imagens_produto.split(', ');
            const novasUrlsProduto = urlsProduto.map(url => {
                const match = url.match(/id=([^&]+)/);
                if (match && match[1]) {
                    return `https://lh3.googleusercontent.com/d/${match[1]}`;
                }
                return url; // Retorna a URL original se não encontrar um ID
            });
            objetoAtualizado.imagens_produto = novasUrlsProduto;
        }
        return objetoAtualizado;
    }

    function renderEmpresas(empresas) {
        if (!empresas?.length) {
            return <h1>Carregando...</h1>;
        } else {
            return empresas.map((empresa) => (
                <AdmCompany key={empresa.id} empresa={empresa} reLoad={atualizar} />
            ));
        }
    }

    function atualizar() {
        getEmpresas().then(empresas_buscadas => {
            setEmpresas(empresas_buscadas);
            setEmpresasManipuladas(empresas_buscadas);
        }).finally(() => {
            setSearch(search);
        });
    }

    async function downloadEmpresas() {
        try {
            setLoading(true);
            const response = await axiosInstance.post("/empresas/exportCsv", { data: { empresas: empresasManipuladas } }, { responseType: 'blob' });
            const data = response.data;

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'empresas.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <section className={style.container}>
            <div className={style.container_internal}>
                <h1 className={style.descricao_pagina}>PÁGINA DO ADMINISTRADOR</h1>
                <Logo style={style} />

                <div className={style.filter_container}>
                    <div className={style.title_excel}>
                        <div className={style.filter_title}>
                            <p>Cadastros:</p>
                            <ButtonBarRS />
                        </div>
                        <img src={excel} alt="Download das empresas" className={style.excel_img} onClick={() => { downloadEmpresas() }} />
                    </div>
                    <div className={style.div_input}>
                        <div className={style.input_lupa}>
                            <input className={style.filter_input} value={search} type="text" onChange={(event) => setSearch(event.target.value)} />
                            <img src={lupa} alt="Mais" className={style.filter_img} />
                        </div>
                    </div>
                </div>
                <div className={style.div_filtros}>
                    <DropdownFilter name="segmentos" estilo={style.estilo_externo} estilo_drop={style.drop} dropdown_parent={[currentContext, setcurrentContext]} data={filtros.segmentos} title="Segmento" />
                    <DropdownFilter name="cidades" estilo={style.estilo_externo} estilo_drop={style.drop} dropdown_parent={[currentContext, setcurrentContext]} data={filtros.cidades} title="Cidade" />
                    <DropdownFilter name="publicado" estilo={style.estilo_externo} estilo_drop={style.drop} dropdown_parent={[currentContext, setcurrentContext]} data={["Sim", "Não"]} title="Publicado" />
                </div>

                {
                    renderEmpresas(empresasManipuladas)
                }
            </div>

            {
                loading && <Loading />
            }

        </section>
    );
}