import React from "react"
import logo from "../../assets/images/coracao.png";


export default function Logo({ style }) {
    return (
        <div className={style.logo_container}>
            <img className={style.image_logo} src={logo} alt="logo" />
            <div className={style.logo_text}>
                <h1 className={style.title}>SOS NEGÓCIOS GAÚCHOS</h1>
                <p className={style.subtitle}>Unidos pelo Rio Grande do Sul</p>
            </div>
        </div>
    );
}