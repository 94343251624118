import React, { useEffect } from "react";
import style from "../../assets/styles/Home/Home.module.css";
import ButtonBarRS from "../Utils/ButtonBarRS";
import Navbar from "../Utils/Navbar";
import About from "./About";


export default function Home() {

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(3));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <section>
            <section className={style.home}>
                <div className={style.home_internal}>
                    <Navbar home_page={true} />

                    <div className={style.container_main_text}>
                        <h1 className={style.main_text}>
                            Conecte-se com negócios gaúchos afetados pelas enchentes e faça a diferença!
                        </h1>
                        <h1 className={style.main_text}>
                            Explore, descubra e fortaleça os empreendimentos do Rio Grande do Sul.
                        </h1>
                    </div>

                    <ButtonBarRS text={"Conheça todas as empresas"} fontSize={"clamp(18px, 5vw, 22px)"} path={"/catalog"} />
                </div>
            </section>
            <About />
        </section>
    );
}