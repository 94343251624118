import React from 'react';
import style from '../../assets/styles/Utils/Pagination.module.css';
import imgIcon from '../../assets/images/seta_next.png';
import ButtonBarRS from './ButtonBarRS';

const MAX_PAGINAS = 5;
const MAX_PAGINAS_LATERAIS = (MAX_PAGINAS - 1) / 2;

export default function Pagination({ limitePorPagina, totalElementosRecebidos, quantidadeElementosPulados, setQuantidadeElementosPulados }) {

    const paginaAtual = quantidadeElementosPulados ? (quantidadeElementosPulados / limitePorPagina) + 1 : 1;
    const ultimaPagina = Math.ceil(totalElementosRecebidos / limitePorPagina);
    let primeiraPagina = Math.max(paginaAtual - MAX_PAGINAS_LATERAIS, 1);
    let ultimaPaginaExibida = Math.min(primeiraPagina + MAX_PAGINAS - 1, ultimaPagina);

    // Ajuste para quando estamos perto do fim e não temos MAX_PAGINAS para exibir
    if (ultimaPaginaExibida - primeiraPagina < MAX_PAGINAS - 1) {
        primeiraPagina = Math.max(ultimaPaginaExibida - MAX_PAGINAS + 1, 1);
    }

    function mudarPaginaAtual(page) {
        setQuantidadeElementosPulados((page - 1) * limitePorPagina)
        window.scrollTo(0, 0);
    }

    return (
        <div id='paginacao' className={style.container_out}>
            <ul className={style.container}>
                <li className={style.li_button_next}>
                    <button className={style.button_next} onClick={() => { mudarPaginaAtual(paginaAtual - 1) }} disabled={paginaAtual === 1}> <img className={`${style.icone_next} ${style.prev}`} src={imgIcon} /></button>
                </li>
                {
                    Array.from({ length: (ultimaPaginaExibida - primeiraPagina) + 1 })
                        .map((_, index) => primeiraPagina + index)
                        .map((page) => {
                            return (
                                <li key={page}><button className={`${style.page_number} ${page === paginaAtual ? style.active_page : ""}`} onClick={() => { mudarPaginaAtual(page) }}> {page} </button></li>
                            );
                        })
                }
                <li className={style.li_button_next}>
                    <button className={style.button_next} onClick={() => { mudarPaginaAtual(paginaAtual + 1) }} disabled={paginaAtual === ultimaPaginaExibida}> <img className={style.icone_next} src={imgIcon} /> </button>
                </li>
            </ul>
            <ButtonBarRS />
        </ div>
    );
};