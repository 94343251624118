import React, { useEffect, useState, useRef } from "react";
import Option from "./Option";

import imgIcon from "../../assets/images/seta_baixo.png";
import style from "../../assets/styles/Utils/DropdownFilter.module.css";

export default function DropdownFilter({ title, data, dropdown_parent, name, estilo, estilo_drop }) {

    const [activeSubMenu, setactiveSubMenu] = useState(false);
    const [currentContext, setCurrentContext] = dropdown_parent;

    const dropdownRef = useRef();

    useEffect(() => {
        const pageClickEvent = (e) => {
            if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target) && !e.target.id.includes("open_drop")) {
                setactiveSubMenu(false);
                setCurrentContext(false);
                return;
            }
        };

        if (activeSubMenu) {
            window.addEventListener('click', pageClickEvent);
        }

        return () => {
            window.removeEventListener('click', pageClickEvent);
        }
    }, [activeSubMenu]);

    useEffect(() => {
        if (currentContext !== activeSubMenu) {
            setactiveSubMenu(false);
        };
    }, [currentContext]);

    function handleClick({ target }) {
        if (activeSubMenu === (target.tagName == "DIV" ? target : target.parentElement)) {
            setactiveSubMenu(false);
            setCurrentContext(false);
            return;
        }
        setactiveSubMenu(target.tagName == "DIV" ? target : target.parentElement)
        setCurrentContext(target.tagName == "DIV" ? target : target.parentElement)
    }

    return (
        <div ref={dropdownRef} className={`${style.dropdown_external} ${estilo}`}>
            <div id="open_drop" className={style.containerDropdown} onClick={handleClick}>
                <p className={style.notClickable}>{title}</p>
                <img className={`${style.iconeDropdown} ${style.notClickable}`} src={imgIcon} alt="Seta para baixo"></img>
            </div>
            {
                activeSubMenu &&
                <div className={`${style.filter_options} ${estilo_drop}`}>
                    {data.length > 0 ?
                        data.map((item, index) => (
                            <Option key={index} name={name} option={item} style={style} />
                        ))
                        : <p>Carregando...</p>
                    }
                </div>
            }
        </div>
    );
}