import React from "react";
import "./assets/styles/style.css"
import { RouterProvider, createHashRouter } from "react-router-dom";

import foto from "./assets/images/coracao.png";
import Public from "./components/Public";
import PrivateRoute from "./components/private/PrivateRoute";
import Login from "./components/Login/Login";
import Catalog from "./components/Companies/Catalog";
import Home from "./components/Home/Home";
import Cadaster from "./components/Companies/Cadaster";
import NotFound from "./components/Utils/NotFound";
import { Helmet, HelmetProvider } from "react-helmet-async";

const router = createHashRouter([
  {
    path: "/",
    element: <Public />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/catalog",
        element: <Catalog />,
      },
      {
        path: "/Cadaster",
        element: <Cadaster />,
      }
    ]
  },
  {
    path: "/admin",
    element: <PrivateRoute />
  },
  {
    path: "*",
    element: <NotFound />
  }
]);

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="icon" type="image/png" href={foto} sizes="16x16" />
        <title>SOS Negócios Gaúchos</title>
      </Helmet>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
