import React, { useEffect } from "react";
import style from "../../assets/styles/Utils/Navbar.module.css";
import Logo from "./Logo";
import ButtonBarRS from "./ButtonBarRS";


export default function Navbar({ home_page }) {
    return (
        <nav className={home_page && style.home_page}>
            <p className={style.voluntary_text}>ORGANIZAÇÀO VOLUNTÁRIA SEM FINS LUCRATIVOS</p>
            <div className={style.navbar} style={home_page && { width: "100%" }}>
                <Logo style={style} />

                <div className={style.menu}>
                    <ButtonBarRS text={"Sobre Nós"} path={"/#about"} />
                    <ButtonBarRS text={"Cadastre sua empresa"} path={"/cadaster"} />
                    <ButtonBarRS text={"Empresas"} path={"/catalog"} />
                </div>
            </div>
        </nav>
    );
}