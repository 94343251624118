import React from "react";
import style from "../../assets/styles/Utils/Loading.module.css";

export default function Loading() {
    return (
        <div className={style.loading}>
            <div className={style.loading_spinner}>
                <div className={style.dot}></div>
                <div className={style.dot}></div>
                <div className={style.dot}></div>
            </div>
        </div>
    );
}