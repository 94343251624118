import React, { useState, useContext, useEffect } from "react";
import style from "../../assets/styles/Companies/Companies.module.css";


import { FiltersContext } from "../context/FilterContext";
import ButtonBarRS from "../Utils/ButtonBarRS";
import DropdownFilter from "../Utils/DropdownFilter";
import AppliedFilters from "./AppliedFilters";
import Company from "./Company";
import faixa from "../../assets/images/faixa_rs_about.png";
import axiosInstance from "../../axios/config";
import Loading from "../Utils/Loading";
import Pagination from "../Utils/Pagination";
import lupa from "../../assets/images/pesquisa_de_lupa.png";

const LIMITE_POR_PAGINA = 10;


export default function Companies() {

    const [currentContext, setcurrentContext] = useState(false);
    const filters = useContext(FiltersContext);
    const [filtros, setFiltros] = useState({ cidades: [], segmentos: [] });
    const [empresas, setEmpresas] = useState([]);
    const [empresasManipuladas, setEmpresasManipuladas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalElementosRecebidos, setTotalElementosRecebidos] = useState(0);
    const [quantidadeElementosPulados, setQuantidadeElementosPulados] = useState(0);

    useEffect(() => {
        async function fetchEmpresas() {
            const empresas_buscadas = await getEmpresas();

            if (!empresas_buscadas) {
                setEmpresasManipuladas({ noResults: true })
                return;
            };

            setEmpresas(empresas_buscadas);
            setEmpresasManipuladas(empresas_buscadas);

        }
        async function fetchFiltros() {
            try {
                let cidades = new Set();
                let segmentos = new Set();
                const filtros = await getFilters();

                filtros.map((filter) => {
                    cidades.add(filter.cidade);
                    segmentos.add(filter.segmento);
                });

                cidades = [...cidades].sort();
                segmentos = [...segmentos].sort();
                cidades = cidades.flatMap(cidade => {
                    if (cidade.includes(",")) {
                        return cidade.split(",").map(cidade => cidade.trim());
                    } else {
                        return cidade.trim();
                    }
                }).filter(cidade => cidade.trim() !== "");

                const segmentosFinal = segmentos.map(segmento => segmento.trim()).filter(segmento => segmento.trim() !== "");

                cidades = [...new Set(cidades.sort())];

                setFiltros({
                    cidades: [...cidades],
                    segmentos: [...segmentosFinal]
                });

            } catch (error) {
                console.log(error);
            }
        }

        fetchEmpresas();
        fetchFiltros();
    }, []);

    useEffect(() => {
        async function fetchEmpresas() {
            try {
                setLoading(true);
                const response = await axiosInstance.post(`/empresas/getPaginated?offset=${quantidadeElementosPulados}&limit=${LIMITE_POR_PAGINA}`);
                const response_data = response.data.rows;

                let data = response_data.map((empresa) => {
                    return transformarLinksImagens(empresa);
                })

                setEmpresas(data);
                setEmpresasManipuladas(data);
            } catch (error) {
                return alert("Erro ao buscar as empresas");
            } finally {
                setLoading(false);
            }
        }

        fetchEmpresas();
    }, [quantidadeElementosPulados]);


    async function getFilters() {
        try {
            setLoading(true);
            const response = await axiosInstance.get("/empresas/getFilters");
            return response.data;
        } catch (error) {
            return alert("Erro ao buscar os filtros");
        } finally {
            setLoading(false);
        }
    }

    async function getEmpresas() {
        try {
            setLoading(true);
            const response = await axiosInstance.post(`/empresas/getPaginated?offset=${quantidadeElementosPulados}&limit=${LIMITE_POR_PAGINA}`);
            const response_data = response.data.rows;

            let data = response_data.map((empresa) => {
                return transformarLinksImagens(empresa);
            })

            setTotalElementosRecebidos(response.data.count);
            return data;
        } catch (error) {
            return alert("Erro ao buscar as empresas");
        } finally {
            setLoading(false);
        }
    }

    function transformarLinksImagens(objeto) {
        let objetoAtualizado = { ...objeto };
        if (objeto.imagens_antes) {
            const urls_no_space = objeto.imagens_antes.replace(/ /g, '');
            const urlsAntes = urls_no_space.split(',');
            const novasUrlsAntes = urlsAntes.map(url => {
                const match = url.match(/id=([^&]+)/);
                if (match && match[1]) {
                    return `https://lh3.googleusercontent.com/d/${match[1]}`;
                }
                return url; // Retorna a URL original se não encontrar um ID
            });
            objetoAtualizado.imagens_antes = novasUrlsAntes;
        }

        if (objeto.imagens_produto) {
            const urlsProduto = objeto.imagens_produto.split(', ');
            const novasUrlsProduto = urlsProduto.map(url => {
                const match = url.match(/id=([^&]+)/);
                if (match && match[1]) {
                    return `https://lh3.googleusercontent.com/d/${match[1]}`;
                }
                return url; // Retorna a URL original se não encontrar um ID
            });
            objetoAtualizado.imagens_produto = novasUrlsProduto;
        }
        return objetoAtualizado;
    }

    async function buscarEmpresas() {
        try {
            setLoading(true);
            const response = await axiosInstance.post(`/empresas/getPaginated?offset=${0}&limit=${LIMITE_POR_PAGINA}`, {
                filtros: {
                    segmentos: filters.segmentos.values,
                    cidades: filters.cidades.values,
                }
            });
            const response_data = response.data.rows;

            let empresas_buscadas = response_data.map((empresa) => {
                return transformarLinksImagens(empresa);
            })

            if (empresas_buscadas.length === 0) {
                setEmpresasManipuladas({ noResults: true })
                return;
            };

            setEmpresas(empresas_buscadas);
            setEmpresasManipuladas(empresas_buscadas);
            setQuantidadeElementosPulados(0);
            setTotalElementosRecebidos(response.data.count);


        } catch (error) {
            return alert("Erro ao buscar as empresas");
        } finally {
            setLoading(false);
        }
    }

    function renderEmpresas(empresas) {
        if (empresas?.noResults) {
            return <h1 style={{ height: "200px" }}>Nenhuma empresa encontrada</h1>;
        } else {
            return empresas.map((empresa) => (
                empresa.publicado &&
                <Company key={empresa.id} empresa={empresa} />
            ));
        }
    }

    return (
        <section className={style.container}>
            <div className={style.container_internal}>
                <div className={style.title}>
                    <h1>Negócios Gaúchos</h1>
                    <ButtonBarRS />
                </div>
                <div className={style.filter_bar}>
                    <DropdownFilter name={"segmentos"} estilo={style.estilo_externo} estilo_drop={style.drop} dropdown_parent={[currentContext, setcurrentContext]} data={filtros.segmentos} title="Segmento" />
                    <DropdownFilter name={"cidades"} estilo={style.estilo_externo} estilo_drop={style.drop} dropdown_parent={[currentContext, setcurrentContext]} data={filtros.cidades} title="Cidade" />
                    <button className={style.search_companies} onClick={buscarEmpresas}><img className={style.icon_search} src={lupa} /></button>
                </div>

                <AppliedFilters />

                <div className={style.empresas}>
                    {
                        renderEmpresas(empresasManipuladas)
                    }
                </div>

                <Pagination totalElementosRecebidos={totalElementosRecebidos} limitePorPagina={LIMITE_POR_PAGINA} quantidadeElementosPulados={quantidadeElementosPulados} setQuantidadeElementosPulados={setQuantidadeElementosPulados} />

            </div>

            <img src={faixa} className={`${style.faixa} ${style.top}`} />
            <img src={faixa} className={`${style.faixa} ${style.bottom}`} />

            {loading && <Loading />}
        </section>
    );
}