import React, { useEffect, useState } from 'react';
import style from "../../assets/styles/Utils/Carousel.module.css";

import seta from "../../assets/images/seta-direita.png";
import rs from "../../assets/images/RS.png";
import ImageWithFallback from './ImageWithFallback';

export default function Carousel({ style_parent, empresa }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [imagemAtual, setImagemAtual] = useState();

    const [images, setImages] = useState(() => {
        let newImages = [];
        if (empresa.imagens_produto.length > 0) {
            newImages = [...newImages, ...empresa.imagens_produto];
        }
        if (empresa.imagens_antes.length > 0) {
            newImages = [...newImages, ...empresa.imagens_antes];
        }
        return newImages;
    });

    useEffect(() => {
        if (images.length > 0) {
            setImagemAtual(images[activeIndex]);
        }
    }, [images, activeIndex]); // Adicionado activeIndex como dependência

    const goToPrev = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        imagemAtual ?
            <div className={`${style_parent.imagem_empresa} ${style.div_carroussel}`}>
                <img src={seta} className={`${style.buttons} ${style.previous}`} onClick={goToPrev} alt="Anterior" />
                <ImageWithFallback key={imagemAtual} src={imagemAtual} alt="Imagem do seu negócio" style={style} />
                <img src={seta} className={`${style.buttons} ${style.next}`} onClick={goToNext} alt="Próximo" />
            </div>
            :
            <div className={`${style_parent.imagem_empresa} ${style.div_carroussel}`}>
                <img className={style.image} src={rs} alt='Imagem do RS' />
            </div>
    );
};