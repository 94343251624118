import React, { createContext, useState, useEffect } from "react";
import { getTokenLocal, removeTokenLocal, setTokenLocal } from "./Token";
import { getUserLocal, setUserLocal, removeUserLocal } from "./User";
import axiosInstance from "../../axios/config";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {

    const initialUser = () => {
        const userInfo = getUserLocal();
        return userInfo ? userInfo : null;
    }

    const initialToken = () => {
        const tokenInfo = getTokenLocal();
        return tokenInfo ? tokenInfo : null;
    }

    const verifyAuthenticate = async () => {
        const specificUrl = ["#/admin", "#/login"];

        if (!specificUrl.includes(window.location.hash)) return false;

        if (!token) return false;

        try {
            const response = await axiosInstance.get("/usuario/verificarToken", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200 && response.data.isValid) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    };

    const [user, setUser] = useState(initialUser);
    const [token, setToken] = useState(initialToken);
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const authenticate = async () => {
            return await verifyAuthenticate();
        };

        return authenticate();
    });

    useEffect(() => {
        setToken(getTokenLocal());
    }, []);

    useEffect(() => {
        const authenticate = async () => {
            const isValid = await verifyAuthenticate();
            setIsAuthenticated(isValid);
        };

        authenticate();
    }, [token]);

    const login = (user, token) => {
        setUser(user);
        setToken(token);
        setTokenLocal(token);
        setUserLocal(user);
    };

    const logout = () => {
        setUser(null);
        setToken(null);
        removeTokenLocal();
        removeUserLocal();
    };

    return (
        <AuthContext.Provider value={{ user, token, isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}
