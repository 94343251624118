import React from "react";

import style from "../../assets/styles/Utils/ConfirmOperation.module.css";

export default function ConfirmOperation({ message, confirm, cancel }) {
    return (
        <div className={style.container}>
            <div className={style.box}>
                <p>{message}</p>
                <div className={style.buttons}>
                    <button className={style.confirma} onClick={confirm}>Sim</button>
                    <button className={style.recusa} onClick={cancel}>Não</button>
                </div>
            </div>

        </div>
    );
}