import React, { useEffect, useState } from "react";
import style from "../../assets/styles/Companies/Company.module.css";

import mais from "../../assets/images/mais.png";
import menos from "../../assets/images/menos.png";
import insta from "../../assets/images/instagram.png";
import whats from "../../assets/images/whatsApp.png";
import web from "../../assets/images/web.png";
import local from "../../assets/images/local.png";

import Carroussel from "../Utils/Carroussel";

export default function Company({ id, empresa }) {

    const [menuEmpresa, setMenuEmpresa] = useState(false);

    function toggleMenuEmpresa() {
        setMenuEmpresa(!menuEmpresa);
    }

    function verificarEAdicionarHttps(url) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `https://${url}`;
        }
        return url;
    }

    return (
        <div className={style.empresa}>
            <div className={style.container_imagem_title}>

                <Carroussel style_parent={style} empresa={empresa} />
                <div className={style.empresa_texts}>
                    <div className={style.empresa_title}>
                        <h1 className={style.nome_empresa}>{empresa.empresa.length > 41 ? empresa.empresa.slice(0, 41) + '...' : empresa.empresa}</h1>
                        <p className={style.desc_empresa}>{empresa.proposta_negocio}</p>
                    </div>
                    <div onClick={toggleMenuEmpresa} className={style.empresa_infos}>
                        <p>Veja o que aconteceu com esse negócio e saiba como ajudar:</p>
                        <img className={style.icone_ver_mais} src={menuEmpresa ? menos : mais} />
                    </div>
                </div>

            </div>

            <div className={style.mais_info}>
                <div className={style.meios_contato}>
                    <div className={style.icon_text}>
                        <img className={style.icon} src={whats} alt="WhatsApp" />
                        <a href={`tel:+55${empresa.telefone}`} target="_blank" >{empresa.telefone}</a>
                    </div>
                    {
                        empresa.instagram &&
                        <div className={style.icon_text}>
                            <img className={style.icon} src={insta} alt="Instagram" />
                            <a href={`https://www.instagram.com/${empresa.instagram.replace(/^@/, '')}`} target="_blank">{empresa.instagram.replace(/^@/, '')}</a>
                        </div>
                    }
                    {
                        empresa.link_site &&
                        <div className={style.icon_text}>
                            <img className={style.icon} src={web} alt="Site" />
                            <a href={verificarEAdicionarHttps(empresa.link_site)} target="_blank">{empresa.link_site}</a>
                        </div>
                    }
                    <div className={style.icon_text}>
                        <img className={style.icon} src={local} alt="Endereço" />
                        {
                            empresa.endereco === "Virtual" ? <p>Negócio virtual</p> : <a href={`https://www.google.com/maps/search/?api=1&query=${empresa.endereco}`} target="_blank">{empresa.endereco}</a>
                        }
                    </div>


                </div>
                {
                    menuEmpresa &&
                    <div className={style.afetada}>
                        <h4>Como fomos afetados: </h4>
                        <p>
                            {empresa.danos}
                        </p>
                        <h4>Como podes ajudar: </h4>

                        <div className={style.how_help}>
                            {
                                empresa.necessidades.includes("Doações em dinheiro") &&
                                <ul>
                                    <li>Doações em dinheiro</li>
                                    {empresa.pix && <p>Chave/Vaquinha - {empresa.pix}</p>}
                                    <br></br>
                                </ul>
                            }
                            {empresa.necessidades.includes("Mão de obra") &&
                                <li>
                                    Mão de obra
                                    <br></br>
                                </li>}
                            {empresa.necessidades.includes("Doações de materiais") &&
                                <ul>
                                    <li>Doações de materiais</li>
                                    {empresa.itens_doacao && <p>{empresa.itens_doacao}</p>}
                                    <br></br>
                                </ul>
                            }
                            {empresa.necessidades.includes("Alcançar mais clientes") &&
                                <li>
                                    Alcançar mais clientes
                                    <br></br>
                                </li>}

                        </div>
                    </div>
                }
            </div>

        </div >
    );
}