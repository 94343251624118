import React, { useState, useContext, useEffect } from "react";
import style from "../../assets/styles/Login/Login.module.css";
import Logo from "../Utils/Logo";
import ButtonBarRS from "../Utils/ButtonBarRS";
import bandeira from "../../assets/images/faixa_rs.png";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/config";
import { AuthContext } from "../context/AuthContext";
import Loading from "../Utils/Loading";

export default function Admin() {

    const navigate = useNavigate();
    const { login, isAuthenticated } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
        senha: ""
    });

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/admin");
        }
    }, [isAuthenticated])

    async function logar() {
        try {
            setLoading(true);
            const response = await axiosInstance.post("/usuario/login", formData);

            if (response.status === 200) {
                await login(formData.email, response.headers['authorization']);
            }
        } catch (error) {
            // Verifica se o erro é um erro de resposta HTTP
            if (error.response) {
                // Aqui você pode tratar diferentes códigos de status de erro, se necessário
                switch (error.response.status) {
                    case 401: // Não autorizado
                        alert("Email ou senha incorretos. Por favor, tente novamente.");
                        break;
                    case 500: // Erro interno do servidor
                        alert("Estamos enfrentando problemas técnicos. Por favor, tente novamente mais tarde.");
                        break;
                    default:
                        alert("Ocorreu um erro ao tentar fazer login. Por favor, tente novamente.");
                }
            } else if (error.request) {
                // O pedido foi feito, mas não houve resposta
                alert("Não foi possível estabelecer uma conexão com o servidor. Por favor, verifique sua conexão com a internet.");
            } else {
                // Algo aconteceu na configuração do pedido que causou um erro
                alert("Ocorreu um erro desconhecido. Por favor, tente novamente.");
            }
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <section className={style.container_login}>
            <div className={style.internal_container}>
                <h1 className={style.title_admin}>PÁGINA DO ADMINISTRADOR</h1>

                <Logo style={style} />

                <div className={style.container_form}>
                    <div className={style.div_input}>
                        <div className={style.title_input}>
                            <p>Login</p>
                            <ButtonBarRS />
                        </div>
                        <input type="text" placeholder="" value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} className={style.input} />
                    </div>
                    <div className={style.div_input}>
                        <div className={style.title_input}>
                            <p>Senha</p>
                            <ButtonBarRS />
                        </div>
                        <input type="password" value={formData.senha} onChange={e => setFormData({ ...formData, senha: e.target.value })} placeholder="" className={style.input} />
                    </div>

                    <button onClick={logar} className={style.button}>Entrar</button>
                </div>
            </div>

            <img src={bandeira} alt="Faixa do RS" className={style.faixaRs} />

            {
                loading && <Loading />
            }
        </section>
    );
}